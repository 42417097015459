<template>
    <v-row v-if="['Animator', 'Admin', 'SuperAdmin'].includes(profile.role)">
        <v-col cols="12" md="6">
            <v-card>
                <v-card-title>Alertes</v-card-title>
                <v-card-text class="d-flex flex-column align-center gap-l">
                    <v-row>
                        <v-col cols="12" class="d-flex align-center gap-m">
                            <v-text-field hide-details label="Message" v-model="configData.text" />
                        </v-col>
                        <v-col cols="12" class="d-flex align-center gap-m">
                            <v-text-field hide-details type="date" v-model="configData.date" label="Date d'envoi" />
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field hide-details type="time" v-model="configData.time" label="Heure d'envoi" v-bind="attrs" v-on="on" />
                                </template>
                                <span>Les alertes sont affichées pendant 5 minutes à partir de l'heure indiquée.</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>

                    <v-col class="d-flex flex-wrap align-center justify-center gap-l">
                        <p class="mb-0">Envoyé à :</p>
                        <v-btn outlined @click="$refs.dialogGetReceiver.open()" class="text-left pa-3" style="height:unset!important;text-transform:unset">
                            <!-- all -->
                            <div v-if="configData.receiver === 'all'" class="d-flex align-center flex-wrap">
                                <UserAvatar :group="true"></UserAvatar>
                                <b class="mx-3">La room</b>
                            </div>
                            <!-- groups -->
                            <div v-else-if="configData.receiver && configData.receiver.members" class="d-flex align-center">
                                <UserAvatar :group="true"></UserAvatar>
                                <b class="mx-3" v-text="configData.receiver.name"></b>
                            </div>
                            <!-- contact & profile -->
                            <div v-else-if="configData.receiver">
                                <UserCard :profile="configData.receiver"></UserCard>
                            </div>
                        </v-btn>
                        <DialogNewConversation with="users all groups" :blacklist="[]" @convSelected="user => this.configData.receiver = user" ref="dialogGetReceiver" :profile="profile"></DialogNewConversation>
                    </v-col>

                    <v-row class="d-flex flex-column align-center justify-center gap-l" style="width:100%">
                        <v-col cols="12" sm="6" class="alertSelector pointer" v-for="(txtType, i) in types" :key="i" @click="configData.type=txtType" :style="configData.type == txtType ? 'transform: scale(1.1)' : ''">
                            <v-alert width="100%" :type="txtType ? txtType : undefined" :color="txtType == null ? '#27394E' : ''" style="box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;">
                                <div class="d-flex align-center justify-end">
                                    <v-btn icon outlined color="customWhite">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </div>
                            </v-alert>
                        </v-col>
                    </v-row>

                    <div class="d-flex align-center justify-center gap-m mt-3">
                        <v-btn outlined @click="$refs.alertViewerTest.visible = !$refs.alertViewerTest.visible">
                            Tester
                        </v-btn>
                        <v-btn outlined @click="sendAlert">
                            Envoyer
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>


        <v-col cols="12" md="6">
            <v-card max-height="100%">
                <v-card-title>En attente</v-card-title>
                <v-card-text class="d-flex flex-column gap-l">
                    <v-row v-for="alert in alerts" :key="alert.id" class="align-center">
                        <v-col cols="1" @mouseover="setTimeLeftInterval(alert.date)" @mouseleave="clearTimeLeftInterval">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">
                                        <v-icon v-if="alert.date - new Date().getTime() > 0">mdi-timer-outline</v-icon>
                                        <v-icon v-else color="warning">mdi-bell-alert-outline</v-icon>
                                    </div>
                                </template>
                                <span>{{ alert.date - new Date().getTime() > 0 ? `Envoyée dans ${ timeLeftValue }` : 'Alerte affichée' }}</span>
                            </v-tooltip>
                        </v-col>

                        <v-col cols="11" sm="8">
                            <v-alert :type="alert.type" style="box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;">
                                <div class="d-flex align-center justify-end">
                                    <div v-text="alert.text" class="mr-5" style="width:100%"></div>
                                    <v-btn icon outlined color="customWhite">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </div>
                            </v-alert>
                        </v-col>

                        <v-col cols="12" sm="3">
                            <v-row  class="justify-center align-center">
                                <div class="text-center py-1 px-2">
                                    <p class="mb-0">{{ new Date(alert.date).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) }}</p>
                                    <p class="mb-0">{{ new Date(alert.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }) }}</p>
                                </div>

                                <v-btn icon @click="deleteAlert(alert)">
                                    <v-icon color="error">mdi-delete-outline</v-icon>
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>

        <AlertViewer :alert="configData" ref="alertViewerTest" stayHidden="true"></AlertViewer>
    </v-row>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'

import logger from '@/assets/functions/logger'
import Alert from '@/classes/Alert'

import AlertViewer from '@/components/Alert/alertViewer.vue'
import DialogNewConversation from '@/components/Communication/DialogNewConversation.vue'
import UserAvatar from '@/components/UserAvatar.vue'
import UserCard from '@/components/UserCard.vue'

export default {
    props: ['profile'],
    components: { AlertViewer, DialogNewConversation, UserAvatar, UserCard },
    data: () => ({
        configData: {
            text: null,
            type: null,
            receiver: 'all',
            date: new Date().toISOString().slice(0, 10),
            time: new Date().toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }).slice(0, 5)
        },
        types: [
            null,
            'success',
            'info',
            'error'
        ],
        alerts: [],
        unsub: [],
        timeLeftValue: null,
        timeLeftInterval: null
    }),
    methods: {
        sendAlert(){
            this.$refs.alertViewerTest.visible = false
            
            if (this.configData.text === null) {
                Swal.fire({
                    title: 'Aucun message ajouté',
                    text: 'Il faut renseigner un message pour envoyer une alerte.',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            } else if (this.configData.text.trim() === ''){
                Swal.fire({
                    title: 'Aucun message ajouté',
                    text: 'Le message ne peut pas être composé uniquement d\'espaces.',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            } else {
                const tmp_alert = new Alert(
                    null,
                    this.configData.text,
                    this.configData.type,
                    [null, undefined, 'all'].includes(this.configData.receiver) ? 'all' : this.configData.receiver.id,
                    this.profile.currentRoom,
                    [],
                    (this.configData.date && this.configData.time) ? new Date(this.configData.date + ' ' + this.configData.time).getTime() : new Date().getTime()
                )

                logger.log(this.profile.id, 'SEND ALERT', this.profile.email + ' a envoyé une alerte.')
                tmp_alert.save()

                this.configData = {
                    text: null,
                    type: null,
                    receiver: this.configData.receiver,
                    date: new Date().toISOString().slice(0, 10),
                    time: new Date().toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }).slice(0, 5)
                }
            }
        },
        deleteAlert(alert) {
            Swal.fire({
                title: "Etes-vous sûr ?",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Annuler",
            }).then((result) => {
                if(result.isConfirmed){
                    alert.delete()
                    logger.log(this.profile.id, "ALERT DELETED", this.profile.email + " a supprimé l'alerte " + alert.id)
                }
            })
        },
        getTimeLeft(date) {
            let milliseconds = date - new Date().getTime()
            let days = Math.floor(milliseconds / 1000 / 60 / 60 / 24)
            let hours = Math.floor(milliseconds / 1000 / 60 / 60) - (days * 24)
            let minutes = Math.floor(milliseconds / 1000 / 60) - (hours * 60) - (days * 24 * 60)
            let seconds = Math.floor(milliseconds / 1000) - (minutes * 60) - (hours * 60 * 60) - (days * 24 * 60 * 60)

            let str = ''
            if (days > 0) {
                str += `${days} jour${days>1?'s':''}`
            }
            if (hours > 0) {
                if (str != '') str += ', '
                str += `${hours} heure${hours>1?'s':''}`
            }
            if (minutes > 0) {
                if (str != '') str += ', '
                str += `${minutes} minute${minutes>1?'s':''}`
            }
            if (str != '') str += ' et '
            str += `${seconds} seconde${seconds>1?'s':''}`

            return str
        },
        setTimeLeftInterval(date) {
            if (!this.timeLeftInterval) {
                this.timeLeftValue = this.getTimeLeft(date)
                this.timeLeftInterval = setInterval(() => {
                    this.timeLeftValue = this.getTimeLeft(date)
                }, 1000)
            }
        },
        clearTimeLeftInterval() {
            clearInterval(this.timeLeftInterval)
            this.timeLeftInterval = null
        }
    },
    created() {
        this.unsub.push(Alert.listenByRoom(this.profile.currentRoom, data => {
            for (let alert of data) {
                if (alert.date + (5*60*1000) < new Date().getTime()) alert.delete()
                else {
                    setTimeout(() => {
                        alert.delete()
                    }, alert.date + (5*60*1000) - new Date().getTime())
                }
            }
            data.sort((a,b)=>{
                return a.date - b.date
            })
            this.alerts = data
        }))
    }
}
</script>

<style>
.alertSelector{
    transition: 200ms;
}
.alertSelector:hover{
    opacity: 0.8;
}
</style>